import {
  FaPhone,
  FaMailBulk,
  FaDesktop,
  FaBook,
  FaUsers,
  FaLink,
} from "react-icons/fa";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Organigrama from "../../assets/images/about/organigrama-sibucsc.png";

const Management = () => {
  const dataContactos = [
    {
      id: 1,
      nombre: "Andrea Arredondo Avendaño",
      email: "marredondo@ucsc.cl",
      cargo: "Directora de Biblioteca",
      telefono: "+56 412345574",
    },
    {
      id: 2,
      nombre: "Oswald González Godoy",
      email: "oswald.gonzalez@ucsc.cl",
      cargo: "Jefe Unidad de Servicios al Público y Referencia",
      telefono: "+56 412345908",
    },
    {
      id: 3,
      nombre: "Mauricio Peñailillo Acuña",
      email: "mpenailillo@ucsc.cl",
      cargo: "Jefe Unidad de Tecnologías de la Información",
      telefono: "+56 412345583",
    },
    {
      id: 4,
      nombre: "Romina Bruna Rodríguez",
      email: "rbruna@ucsc.cl",
      cargo: "Jefa Unidad de Gestión de la Información",
      telefono: "+56 412345575",
    },
    {
      id: 5,
      nombre: "Flor Urrutia Barra",
      email: "furrutia@ucsc.cl",
      cargo: "Secretaria",
      telefono: "+56 412345570",
    },
  ];

  return (
    <>
      <Container fluid className="web-container pt-3" id="bannerSIBUCSC">
        <Row className="col d-flex justify-content-center">
          <Col xl={4}>
            <div className="col mt-3 d-flex justify-content-center">
              <img
                src={require("../../assets/images/library/direccion.JPG")}
                className="imgBanner shadow-lg"
                style={{ maxHeight: 300, maxWidth: "auto" }}
              />
            </div>
          </Col>
          <Col xl={6} className="mt-md-5">
            <div>
              <div className="m-3">
                <h3 className="main-heading text-white">
                  Dirección de Bibliotecas
                </h3>
                <h6 className="text-justify text-[#C9CCD2]">
                  La Dirección de Bibliotecas de la UCSC, unidad dependiente de
                  la Vicerrectoría Académica, tiene como objetivo dar respuesta
                  a los requerimientos de información de la comunidad
                  universitaria, como también, facilitar el desarrollo de
                  habilidades informacionales en los estudiantes para el acceso
                  y uso ético de la información en los usuarios que así lo
                  requieran. Disponer de los espacios físicos y virtuales,
                  ofreciendo de esta forma las mejores condiciones para el
                  aprendizaje.
                </h6>
              </div>
            </div>
          </Col>
        </Row>
        <div className="divSpaceLibrary"></div>
      </Container>
      <Container className="web-container" fluid>
        <Row className="m-5">
          <Col xl={6} md={12} xs={12}>
            <h3 className="mt-4 text-[#d12421]">
              Dirección del Sistema de Bibliotecas UCSC (SIBUCSC)
            </h3>
            <p className="text-justify">
              {" "}
              En el año 2015 el SIBUCSC incorpora a la totalidad de las
              bibliotecas de la UCSC, por lo que se convierte en un sistema
              integrado que homologa los servicios para todos los estudiantes.
              El acceso automatizado a la totalidad de las colecciones
              disponibles en el catálogo en línea facilita a los usuarios la
              recuperación del material disponible y acceso a los recursos
              electrónicos desde y fuera de la UCSC. El SIBUCSC también inicia
              la ejecución del PM USC1405 "De Biblioteca a CRAI: Modernizar los
              servicios bibliotecarios en apoyo a la enseñanza, el aprendizaje y
              la investigación", cuyo objetivo es la creación de un centro de
              recursos para el aprendizaje y la investigación (CRAI).{" "}
            </p>
            <h3 className="text-[#d12421]">Misión</h3>
            <p className="text-justify">
              {" "}
              Somos un sistema integrado de bibliotecas que gestiona, facilita y
              promueve los recursos de la información a la docencia,
              investigación y postgrado contribuyendo al quehacer académico de
              la institución.{" "}
            </p>
            <h3 className="text-[#d12421]">Visión</h3>
            <p className="text-justify">
              {" "}
              Ser un referente nacional reconocida por una gestión innovadora y
              de calidad, comprometida con el proceso formativo de los
              estudiantes y el desarrollo de la investigación UCSC.{" "}
            </p>
          </Col>
          <Col xl={6} md={12} xs={12}>
            <img src={Organigrama} style={{ width: "750px" }} />
          </Col>
        </Row>
        <Row className="mt-5 d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <h2 className="font-bold text-[#646567]">
              Contactos
              <hr></hr>
            </h2>
          </div>
          <div className="row">
            {dataContactos.map((dat) => (
              <div className="col divManag d-flex m-3 border border-indigo-600 ">
                <div className="contentInfoInt flex-grow-1">
                  <span className="cardTitleInfo mt-5 font-bold">
                    {" "}
                    {dat.cargo}
                  </span>
                  <p className="text-justify mt-3">{dat.nombre}</p>
                  <p>
                    <FaMailBulk className="d-inline mr-1" />
                    {dat.email}
                  </p>
                  <p>
                    <FaPhone className="d-inline mr-1" />
                    {dat.telefono}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <span className="mt-5"></span>
        </Row>
        <Row className="mt-5">
          <div className="d-flex justify-content-center">
            <h2 className="font-bold text-[#646567]">
              Unidades Administrativas
              <hr></hr>
            </h2>
          </div>
          <div className="row">
            <div className="col divManag d-flex m-3 border border-indigo-600 ">
              <div className="contentInfoInt flex-grow-1">
                <span className="cardTitleInfo mt-5 font-bold">
                  <FaUsers className="d-inline mr-1 iconUnit" />
                  Unidad de Servicios al Público y Referencia
                </span>

                <p className="text-justify mt-3">
                  {" "}
                  La Unidad de Servicios al Público y Referencia es la encargada
                  de dar respuesta oportuna y satisfactoria a las necesidades de
                  información por parte de nuestros usuarios. Para ello, posee
                  una serie de servicios tales como préstamos, renovaciones en
                  línea, reserva, referencia y hemeroteca, conmutación
                  bibliográfica, acceso a bases de datos y otros. Además,
                  constantemente evalúa, corrige e implementa medidas
                  correctivas a dichos servicios e incorpora nuevos productos o
                  servicios acordes a las necesidades de la comunidad
                  universitaria en el desarrollo de las tecnologías de
                  información y comunicación (TICs).
                </p>
              </div>
            </div>
            <div className="col divManag d-flex m-3 border border-indigo-600 ">
              <div className="contentInfoInt flex-grow-1">
                <span className="cardTitleInfo mt-5 font-bold">
                  <FaBook className="d-inline mr-1 iconUnit" />
                  Unidad de Gestión de Información
                </span>

                <p className="text-justify mt-3">
                  {" "}
                  La Unidad de Gestión de Información tiene como objetivo
                  desarrollar la colección de acuerdo a los requerimientos de la
                  comunidad universitaria. Para esto está conformada de dos
                  áreas, Adquisiciones, a través de la cual se canalizan las
                  compras y/o suscripciones de material bibliográfico y Procesos
                  técnicos, en donde se realiza la descripción bibliográfica y
                  el proceso físico del material, permitiendo luego de esto, que
                  el material bibliográfico quede a disposición de los usuarios
                  de la Biblioteca.
                </p>
              </div>
            </div>
            <div className="col divManag d-flex m-3 border border-indigo-600 ">
              <div className="contentInfoInt flex-grow-1">
                <span className="cardTitleInfo mt-5 font-bold">
                  <FaDesktop className="d-inline mr-1 iconUnit" />
                  Unidad de Tecnologías de la Información
                </span>

                <p className="text-justify mt-3">
                  {" "}
                  La Unidad de Tecnologías de Información de la Biblioteca de la
                  UCSC, tiene como objetivo, administrar, desarrollar,
                  implementar y proponer soluciones tecnológicas, que permitan a
                  los usuarios y administrativos optimizar el uso y acceso a los
                  recursos de información tanto impresos como digitales, que
                  concentra el fondo bibliográfico. Además, es la responsable de
                  velar por la mantención y correcto funcionamiento de todo el
                  equipamiento tecnológico existente en cada unidad
                  perteneciente al SiBUCSC.
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Management;
